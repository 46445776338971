<template>
  <b-modal
    id="doctor-view-modal"
    title="Doctor Profile"
    centered
    hide-footer
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <div class="container" style="margin-bottom: 30px">
      <div style="margin-bottom: 30px">
        <b-row>
          <b-col md="4">
            <img
              v-if="doctor && doctor.profile_image"
              :src="doctor.profile_image"
              alt="Doctor Image"
              class="rounded-circle"
              style="max-width: 200px; max-height: 200px"
            />
            <div v-else>
              <!-- Handle case where doctor or profile_image is not available -->
              <span>No profile image available</span>
            </div>
          </b-col>

          <b-col md="8" v-if="doctor">
            <div class="">
              <span>
                <h1 class="font-weight-bolder text-colorBlue">
                  {{ doctor.name }}
                </h1>
              </span>
              <span>
                <h3 class="font-weight-bolder text-colorGreen">
                  {{ doctor.hospital_name }}
                </h3>

                <h4 class="font-weight-bold text-colorGreen">
                  {{ doctor.department_name }}
                </h4>
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
      <h4 class="font-weight-bolder text-colorBlue">Credentials</h4>
      <div
        v-html="doctor.about"
        class="font-weight-bold text-colorText"
        v-if="doctor"
      ></div>

      <h4 class="font-weight-bolder text-colorBlue mt-50">Biosketch</h4>
      <div
        v-html="doctor.activities"
        class="font-weight-bold text-colorText"
        v-if="doctor"
      ></div>

      <!-- <h4 class="prodcedures-heading">Procedures</h4> 
      <b-card-body >
          <b-table
            stacked="md"
            :fields="fieldsProcedures"
            :items="procedures"
            thead-class="tableHeadColor"
            small
            rounded
            style="margin-top:0px;padding-top:0px;"
          >
              <template #head()="data">
                <div class="">
                  <span class="font-weight-bolder text-colorBlack">{{
                    data.label
                  }}</span>

                </div>
              </template>

              <template #cell(manage)="row">
                <div class="">
                  <b-button
                      block
                      rounded
                      variant="colorGreen"
                      size="sm"
                      class="font-weight-bolder mb-sm-1"
                      @click="addCart(row.item)"
                      >Add to Cart
                    </b-button>
                </div>
              </template>
            </b-table>
          </b-card-body> -->
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import util from "@/util.js";

export default {
  props: {
    showModal: Boolean,
    doctor: Object,
  },

  data() {
    return {
      // procedures : [],
      // fieldsProcedures: [
      // { key: "name", label: "Name" },
      //   { key: "amount",label: "Starting From"},
      //   { key: "manage", label: "" },
      // ],
    };
  },
  mixins: [util],

  //async mounted() {
  //  await this.fetchProcedure();
  //},
  methods: {
    ...mapActions({
      getProcedures: "appData/getProceduresPublic",
      addToCart: "appData/addToCart",
    }),
    resetModal() {},
    // async fetchProcedure(){
    //   if (this.doctor.id){
    //     const res_procedure = await this.getProcedures({doctor: this.doctor.id, id: null, hospital: null, department: null});
    //       if(res_procedure.status === 200){
    //         this.ifProcdures = true
    //         this.procedures = res_procedure.data;
    //       }
    //     }
    //   },
    //       addCart(item) {
    //         this.addToCart({
    //           id: `${item.id}_${this.cartItemType.TREATMENT}`,
    //           name: item.name,
    //           departmentName: item.department_name,
    //           price: item.amount,
    //           type: this.cartItemType.TREATMENT,
    //           quantity: 1,
    //       });
    // },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  height: 100%;
}
.flex-container span {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
}
/* .prodcedures-heading{
  margin-bottom:0px;
  margin-top:20px;
  padding-bottom:0px;
} */
</style>
