<template>
  <div>
    <b-row class="">
      <b-col
        md="6"
        class="bg-colorBlue d-flex align-items-center justify-content-center m-0 p-5"
        style="border-bottom: 10px solid green"
      >
        <div v-html="hospital.description"></div>
      </b-col>
      <b-col
        md="6"
        class="m-0 p-0 d-flex align-items-center justify-content-start"
      >
        <img :src="hospital.image" class="img-fluid" width="100%" />
      </b-col>
    </b-row>

    <b-container class="">
      <div class="mt-2 pt-5">
        <h2 class="font-weight-bolder text-colorBlue text-center">
          Specialities
        </h2>
        <b-row class="d-flex align-items-center justify-content-center mt-2">
          <b-col
            md="1"
            class="image-container-hover w-auto ml-1"
            v-for="department in departments"
            :key="department.id"
          >
            <b-link :to="{ name: 'Treatment', query: { id: department.id } }">
              <img :src="department.image" class="img-fluid" />
            </b-link>
            <h6 class="text-colorText font-weight-bolder text-center mt-1">
              {{ department.name }}
            </h6>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <div class="mt-2 p-5 my-bg">
      <h3 class="pt-2 text-center font-weight-bolder text-colorBlue">
        Meet Our Expert Healthcare Team
      </h3>
      <b-row class="mt-3 d-flex justify-content-center">
        <b-col
          md="3"
          class="d-flex align-items-stretch justify-content-center mb-1"
          style="position: relative"
          v-for="(doctor, index) in displayedDoctors"
          :key="index.id"
        >
          <div class="doctor-card d-flex flex-column">
            <div
              class="doctor-image-container"
              v-if="doctor && doctor.profile_image"
            >
              <img
                :src="doctor.profile_image"
                alt="Doctor Image"
                style="width: 100%; height: 300px"
              />
            </div>
            <div v-else>
              <span>No profile image available</span>
            </div>

            <div class="bg-colorBlue py-1 px-2 doctor-card">
              <div class="h-75">
                <h4 class="font-weight-bolder text-white">
                  {{ doctor.name }}
                </h4>
                <h5 class="text-white font-weight-normal multi-line-truncate">
                  {{ doctor.intro }}
                </h5>
              </div>
              <div>
                <b-button
                  @click="openProfileModal(doctor)"
                  class="mt-1"
                  variant="light"
                  >View Details</b-button
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="text-center mt-3 mb-3">
        <b-button
          v-if="currentPage < totalPages"
          @click="showMore"
          class="mr-2"
          variant="colorGreen"
          size="md"
        >
          Show More
        </b-button>
        <b-button
          v-if="currentPage > 1"
          @click="showLess"
          variant="colorBlue"
          size="md"
        >
          Show Less</b-button
        >
      </div>
      <doctor-view-modal
        :doctor="selectedDoctor"
        @modalClosed="closeProfileModal"
        :key="`view-${DoctorViewModal}`"
      />
    </div>

    <!-- <b-container class="mt-5 mb-5">
      <b-row class="d-flex align-items-center justify-content-between">
        <b-col md="12" class="bg-colorBlue rounded">
          <div class="text-center pt-3 pb-4">
            <h2 class="font-weight-bolder text-light">
              Procedure Rates in {{ hospital.name }}
            </h2>
          </div>
          <b-row
            class="px-4 d-flex border-top align-items-center"
            v-for="procedure in procedures"
            :key="procedure.id"
          >
            <b-col>
              <h4 class="text-light font-weight-bolder px-5 m-0">
                {{ procedure.name }}
              </h4>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-end">
              <div class="text-right text-light font-weight-bolder">
                <h4 class="text-light font-weight-bolder px-5 m-0">
                  {{ procedure.duration }} Day
                </h4>
              </div>
            </b-col>
            <b-col class="d-flex align-items-center justify-content-end">
              <div class="text-right text-light font-weight-bolder">
                <h4 class="text-light font-weight-bolder px-5 m-0">
                  From -$ {{ procedure.amount }}
                </h4>
              </div>
          <div class="ml-1 mt-1">
                <div class="text-center">
                  <b-button
                    block
                    rounded
                    :variant="isInCart(procedure) ? 'colorBlue' : 'colorGreen'"
                    size="sm"
                    class="font-weight-bolder mb-sm-1"
                    @click="handleCartAction(procedure)"
                  >
                    {{ isInCart(procedure) ? "View Cart" : "Add to Cart" }}
                  </b-button>
                </div>
              </div>
            </b-col> 
          </b-row>
        </b-col>
      </b-row>
    </b-container> -->

    <b-container class="mt-5 mb-5">
      <h2 class="font-weight-bolder text-colorBlue pt-5 pb-2 text-center">
        {{ hospital.name }} Gallery
      </h2>
      <div class="text-center">
        <carousel :per-page="3" :autoplay="true" :autoplay-timeout="3000">
          <slide v-for="image in galleryImages" :key="image.id">
            <img :src="image.image" class="img-fluid w-100 h-100" />
          </slide>
        </carousel>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DoctorViewModal from "@/components/doctorProfile/DoctorProfileModal.vue";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    ToastificationContent,
    DoctorViewModal,
    Carousel,
    Slide,
  },
  mixins: [util],
  props: {
    hospital: Object,
  },
  data() {
    return {
      departments: [],
      doctors: [],
      displayedDoctors: [],
      procedures: [],
      galleryImages: [],
      showModal: false,
      selectedDoctor: null,
      DoctorViewModal: 0,
      viewAll: false,
      currentPage: 1,
      totalPages: 0,
      itemsPerPage: 5,
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
    visibleDoctors() {
      return this.viewAll ? this.doctors : this.doctors.slice(0, 4);
    },
  },

  methods: {
    ...mapActions({
      getdepartments: "appData/getDepartmentsPublic",
      getdoctors: "appData/getDoctorsPublic",
      getprocedures: "appData/getProceduresPublic",
      getGalleryImages: "appData/getGalleryPublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
    }),

    openProfileModal(doctor) {
      this.DoctorViewModal += 1;
      this.selectedDoctor = doctor;
      this.$nextTick(() => {
        this.$bvModal.show("doctor-view-modal");
      });
    },

    async fetchData() {
      try {
        // Fetch doctors based on the current page
        const res_doctors = await this.getdoctors({
          hospital: this.hospital.id,
          pageNumber: this.currentPage,
        });

        // Set the total number of pages only on the initial load
        if (this.currentPage === 1) {
          this.totalPages = res_doctors.data.total_pages;

          // Fetch other resources only once on the initial page load
          const res_departments = await this.getdepartments({
            hospital: this.hospital.id,
          });
          this.departments = res_departments.data;

          const res_procedures = await this.getprocedures({
            hospital: this.hospital.id,
          });
          this.procedures = res_procedures.data;

          const res_galleryImages = await this.getGalleryImages({
            hospital: this.hospital.id,
          });
          if (res_galleryImages.data.length > 0) {
            this.galleryImages = res_galleryImages.data[0].images;
          }
        }

        // Append new doctors to the existing array in displayedDoctors
        const newDoctors = res_doctors.data.results;
        if (this.currentPage === 1) {
          this.displayedDoctors = newDoctors;
        } else {
          this.displayedDoctors = [...this.displayedDoctors, ...newDoctors];
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    showMore() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.fetchData();
      }
    },

    showLess() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        // Update displayedDoctors to show doctors up to the current page
        this.displayedDoctors = this.displayedDoctors.slice(
          0,
          this.currentPage * this.itemsPerPage
        );
      }
    },
    // addCart(item) {
    //   const cartItemId = `${item.id}_${this.cartItemType.TREATMENT}`;
    //   const isInCart = this.cart.some((cartItem) => cartItem.id === cartItemId);

    //   if (!isInCart) {
    //     this.addToCart({
    //       id: cartItemId,
    //       name: item.name,
    //       departmentName: this.hospital.name,
    //       itemId: item.id,
    //       price: item.amount,
    //       type: this.cartItemType.TREATMENT,
    //       quantity: 1,
    //     });
    //     this.$toast(
    //       {
    //         component: ToastificationContent,
    //         props: {
    //           title: "Item added to cart successfully",
    //           variant: "success",
    //           icon: "BellIcon",
    //         },
    //       },
    //       {
    //         position: "top-right",
    //       }
    //     );
    //   }
    // },
    // handleCartAction(item) {
    //   if (this.isInCart(item)) {
    //     this.$router.push({ name: "Cart" });
    //   } else {
    //     this.addCart(item);
    //   }
    // },
    // isInCart(item) {
    //   const cartItemId = `${item.id}_${this.cartItemType.TREATMENT}`;
    //   return this.cart.some((cartItem) => cartItem.id === cartItemId);
    // },
    closeProfileModal() {
      this.selectedDoctor = null;
    },
    toggleViewAll() {
      this.viewAll = !this.viewAll;
    },
  },

  watch: {
    "hospital.id": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          // Reset currentPage and displayedDoctors when hospital changes
          this.currentPage = 1;
          this.displayedDoctors = [];

          // Fetch data for the new hospital
          this.fetchData();
        }
      },
    },
  },
  async mounted() {
    this.currentPage = 1;
    this.fetchData();
  },
};
</script>

<style scoped>
.bg-colorDoctor {
  background-color: #fcfcfc41;
  color: black;
}

.bg-colorDoctor:hover {
  background-color: #0c1950;
  color: white;
}
.bg-colorBlue {
  background-color: #06bd46;
}

.hover-img {
  display: none;
}
.image-container-hover:hover .hover-img {
  display: block;
}

.image-container-hover:hover .base-img {
  display: none;
}

.my-bg {
  background: url("~@/assets/images/treatment/overlay.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.border-top {
  border-top: 1px solid white;
  padding-top: 20px;
  padding-bottom: 20px;
}

.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: calc(1.5em * 3);
}

.doctor-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.doctor-image-container {
  flex: 1;
}
</style>
